@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
html {
  scroll-behavior: smooth;
}

* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  margin: 0;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(27, 30, 37); 
  padding: 0 1rem;
  position: relative;
  height: 8vh;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.giga_plus_logo {
  width: 2.5%;
  position: absolute; 
  top: 1.2vh;
  left: 12vw;
  gap: 4rem;
}

.nav ul {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
}

.nav_li {
  position: relative;
  left: -8vw;
}

.menu_link {
  position: relative;
}
.menu_link:after {
  content: "";
  position: absolute;
  background-color: rgb(255, 255, 255);
  height: 3.1px;
  width: 80px;
  left: 0.2vw;
  bottom: -6px;
  width: 0px;
  transition: all 0.3s;
}

.menu_link:focus:after {
  width: 50px;
}

.menu_link_active {
  position: relative;
}

.menu_link_active:after {
  content: "";
  position: absolute;
  background-color: rgb(255, 255, 255);
  height: 3.1px;
  width: 80px;
  left: 0.2vw;
  bottom: -6px;
  width: 50px;
  transition: all 0.3s;
}

.nav ul li {
  list-style-type: none;
  display: inline-block;
  padding: 20px 0 20px;
  vertical-align: middle;
}

.nav ul li a {
  color: white;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
  font-weight: 00;
}

.nav_icons {
  position: absolute;
  right: 3.5vw;
}

.nav_icons > li:nth-child(1) > div {
  background-color: #5e91ae;
  color: #2c333a;
}

.nav_icons > li:nth-child(2) > div {
  background-color: #6bad39;
  color: white;
  position: relative;
  left: 1.2vw;
  transition: all 0.2s ease;
}

.setings_icon_active {
  border: 0.15rem solid white;
  background-color: #6bad39;
}

.nav_icons > li:nth-child(3) > div {
  background-color: #ef5a2b;
  color: white;
}
.nav_icon {
  width: 28px;
  height: 28px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.nav_icon i {
  font-size: 1.1rem;
  cursor: pointer;
}

.accueil_container {
  text-align: center;
  font-size: 1.5rem;
  height: 91vh;
  width: 90vw;
}

.dashboard {
  /* background-color: tomato; */
  width: 100vw;
  height: 91vh;
  padding: 0 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.sect1 {
  width: 100%;
  height: 25%;
  border-radius: 9px;
  /* background-color: tomato; */
  display: grid;
  grid-template-columns: 55% 44%;
  gap: 0.8%;
  padding: 0.1rem;
  position: relative;
  top: 5px;
}

.sect1 > div:nth-child(1) {
  border-radius: 9px;
  background-color: rgb(27, 30, 37);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
}

.sect1_bilan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.7rem;
  height: 100%;
  /* background-color: #cdcdcd; */
  gap: 0.6rem;
}

.sect1_bilan > div:nth-child(1) {
  height: 80px;
  width: 80px;
  background-color: white;
  color: #2c333a;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5rem solid #4eac04;
  transition: all 0.3s;
}

.sect1_bilan > span {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.sect1 > div:nth-child(2) {
  border-radius: 9px;
  background-color: #cdcdcd;
  background-size: cover;
}
.sect2 {
  width: 100%;
  height: 20%;
  border-radius: 0.5rem;
  /* background-color: rgba(255, 227, 88, 0.466); */
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 1.05rem;
  gap: 1rem;
  position: relative;
}

.sect2_partner {
  height: 70%;
  width: 12.5vw;
  border-radius: 0.5rem;
  background-color: rgb(27, 30, 37);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  /* gap: 2rem; */
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
    0 8px 16px rgba(0, 0, 0, 0.11);
  /* cursor: pointer; */
}

.sect2_partner > div:nth-child(1) {
  color: #ffffff;
  font-size: 0.58em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  left: -2.8vw;
}

.sect2_partner > div:nth-child(2) {
  width: 68px;
  height: 65px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ef5a2b;
  color: white;
  font-size: 2rem;
  position: absolute;
  right: 0.6vw;
}

.partner_logo {
  width: 100%;
  object-fit: contain;
}

.sect3 {
  width: 100%;
  height: 55%;
  border-radius: 9px;
  /* background-color: rgb(71, 164, 245); */
  display: grid;
  grid-template-columns: 55% 44%;
  gap: 0.8%;
  padding: 0.1rem;
}

.sect3 > div:nth-child(1) {
  border-radius: 9px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.sect3_top {
  width: 100%;
  height: 13%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;
  background-color: #23e00e37;
  /* border-top-left-radius: 9px;
  border-top-right-radius: 9px; */
  font-weight: 700;
}

.sect3_top > div:nth-child(1) {
  border-radius: 9px;
  /* background-color: #2C333A; */
  color: #148208;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  padding: 0.2rem 0.7rem;
}

.sect3_top > div:nth-child(2) {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4eac04;
}

.sect_table {
  width: 100%;
  height: 88%;
  padding: 0.6rem 1.15rem;
}

.sect_table > table {
  border-collapse: separate;
  border-spacing: 6px 6px;
  margin-top: -4px;
}

.sect_table > table > thead {
  font-size: 0.9rem;
}

.sect_table > table > thead > tr {

  /* background-color: #d9d9d9; */

  background-color: #ffffff;
  color: rgb(27, 30, 37);
}

.sect_table > table > thead > tr > th {
  padding: 0.2rem 1.3rem;

  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  border-bottom: 0.1rem solid black;
}

.sect_table > table > tbody > tr {
  margin: 0.5rem 0;
  background-color: #d9d9d9;

  color: #2c333a;
}

.sect_table > table > tbody > tr > td {
  padding: 0.3rem 0;

  white-space: nowrap; /* prevent text from wrapping */
  overflow: hidden; /* hide overflow */
  text-overflow: ellipsis; /* show ellipsis for overflow */
  max-width: 100px; /* adjust as needed */
  font-family: "Inter", sans-serif;
  font-size: 0.85rem;
  font-weight: 400;
}

.sect_table > table > tbody {
  font-size: 0.9rem;
}

.sect3 > div:nth-child(2) {
  border-radius: 9px;
  background-color: #ffffff;
}

.sect3_top2 {
  width: 100%;
  height: 13%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;
  font-weight: 700;
}

.sect3_top2 > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.2rem 0.7rem;
}

/* styles for partenaires page */

.partenaires {
  /* background-color: tomato; */
  width: 100vw;
  height: 92vh;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

.points {
  /* background-color: tomato; */
  width: 100vw;
  height: 92vh;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

.partenaires_sect1 {
  /* background-color: rgba(255, 255, 255, 0.425); */
  width: 70vw;
  height: 99%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.points_sect1{

    /* background-color: rgba(255, 255, 255, 0.425); */
    width: 55vw;
    height: 99%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.geneUser {
  width: 98vw;
  height: 92vh;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}
.partenaires_sect1_top {
  background-color: rgb(27, 30, 37);
  width: 100%;
  height: 32%;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
}

.partenaires_sect1_bottom {
  background-color: #ffffff;
  width: 100%;
  height: 68%;
  border-radius: 0.8rem;
}

.partenaires_sect1_bottom2{

  background-color: rgb(236, 236, 236);

  width: 100%;
  height: 68%;
  border-radius: 0.7rem;

}

.partenaires_sect1_bottom3{

  background-color: rgb(236, 236, 236);

  width: 80%;
  height: 68%;
  border-radius: 0.7rem;

}

.partenaires_sect1_bottom_sect_top {
  width: 52vw;
  height: 13%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0 4rem;
  /* background-color: #55785218; */
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  font-weight: 700;
}

.partenaires_sect1_bottom_sect_topPoints {
  width: 65vw;
  height: 13%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  padding: 0 4rem;
  background-color: #4eac04;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  font-weight: 700;
}

.partenaires_sect1_bottom_sect_topPoints3{

  width: 55vw;
  height: 13%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* padding: 0 4rem; */
   background-color: rgba(255, 255, 255, 0.505);

  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  font-weight: 500;
  font-size: 0.8rem;
}

.partenaires_sect1_bottom_sect_topPoints4{
  width: 55vw;
  height: 13%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  /* padding: 0 4rem; */
   background-color: rgba(255, 255, 255, 0.505);

  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  font-weight: 500;
  font-size: 0.8rem;
}

.partenaires_sect1_bottom_sect_topPoints4 > div:nth-child(1) {
  border-radius: 0.7rem;
  /* background-color: #2C333A; */
  color: #2c333a;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 0.9rem; */
  font-weight: 700;
  /* padding: 0.2rem 0.7rem; */
  position: absolute;
  left: 2.5vw;
}

.partenaires_sect1_bottom_sect_top > div:nth-child(1) {
  border-radius: 0.7rem;
  /* background-color: #2C333A; */
  color: #2c333a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.2rem 0.7rem;
}

.partenaires_sect1_bottom_sect_top > div:nth-child(2) {
  width: 15vw;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 700;
  position: relative;
  left: 1.5vw;
  /* border-radius: 50%; */
  background-color: #2d5c7927;
}

/* partenaires page sect1 bottom part table style */

.partenaires_sect1_bottom_sect_table {
  width: 100%;
  height: 88%;
  padding: 0.6rem 1.15rem;
  /* background-color: rgba(255, 255, 255, 0.505); */
}

.partenaires_sect1_bottom_sect_table::-webkit-scrollbar {
  width: 0.5em;
}

.partenaires_sect1_bottom_sect_table::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

.partenaires_sect1_bottom_sect_table > table {
  border-collapse: separate;
  border-spacing: 6px 6px;
  margin-top: -4px;
  position: relative;
  top: 20px;
}

.partenaires_sect1_bottom_sect_table > table > thead {
  font-size: 0.8rem;
  color: rgb(27, 30, 37);
  border: none;
}

.partenaires_sect1_bottom_sect_table > table > thead > tr {
/* <<<<<<< HEAD */
  background-color: #d9d9d9;

  /* background-color:#ffffff; */
  /* color: rgb(27, 30, 37); */

  border: none;
}

.partenaires_sect1_bottom_sect_table > table > thead > tr > th {
  padding: 0.2rem 3.1rem;
  border-radius: 0rem;
  border-bottom: 0.1rem solid black;
}

.partenaires_sect1_bottom_sect_table2 > table > thead > tr > th {
  padding: 0.2rem 1.4rem;
  border-radius: 0rem;
  border-bottom: 0.1rem solid 
  black;

}




.partenaires_sect1_bottom_sect_table3 > table > thead > tr > th{
  padding: 0.2rem 1.6rem;
  border-radius: 0rem;
  border-bottom: 0.1rem solid 
  black;
}

.partenaires_sect1_bottom_sect_table > table > tbody > tr {
  margin: 0.5rem 0;
  background-color: #d9d9d9;
  color: #2c333a;
}

.partenaires_sect1_bottom_sect_table > table > tbody > tr > td {
  padding: 0.3rem 0;
  white-space: nowrap; /* prevent text from wrapping */
  overflow: hidden; /* hide overflow */
  text-overflow: ellipsis; /* show ellipsis for overflow */
  max-width: 100px; /* adjust as needed */
}

.partenaires_sect1_bottom_sect_table > table > tbody {
  font-size: 0.86rem;
}

/* style for partenaires page sect2 */
.partenaires_sect2 {
  background-color: #ffffff;
  width: 30vw;
  height: 99%;
  border-radius: 0.8rem;
}

.points_sect2{


  background-color: white;
  width: 45vw;
  height: 99%;
  border-radius: 0.8rem;
  position: relative;

}

.gestions_sect2_top{

  height: 49.5%;
  width: 100%;
  position: relative;
  top: 0;
  background-color: #d9d9d9;
  border-radius: 0.7rem;


}

.gestions_sect2_bottom{

  height: 49.5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #d9d9d9;
  border-radius: 0.7rem;

}

.partenaires_sect2_part_top {
  width: 100%;
  height: 15%;
  /* background-color: #2C333A; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.partenaires_sect2_part_top > div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 3vh;
  width: 15vw;
  height: 6vh;
  border-radius: 0.8rem;
  color: rgb(27, 30, 37);
  font-size: 1rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.partenaires_sect2_part_bottom {
  width: 100%;
  height: 85%;
  /* background-color: #efefef; */
  border-radius: 9px;
  display: flex;
  align-items: center;
  position: relative;
  bottom: 9vh;
  justify-content: center;
}

.partenaires_add_form {
  width: 90%;
  height: 90%;
  background-color: rgb(255, 255, 255);
  border-radius: 9px;
  position: relative;
  gap: 0.5rem;
  padding: 1rem;
}

.partenaires_add_form2 {
  width: 90%;
  height: 90%;
  background-color: rgb(255, 255, 255);
  border-radius: 0.8rem;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  position: relative;
  gap: 0.5rem;
  /* padding: 1rem; */
}

/* modif du 30/01/2023 pour la creation sécurisé de compte partenaire */

.partenaires_tab_type {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0.4rem;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: rgb(27, 30, 37);
}

.partenaires_tab_type > div {
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  border-radius: 0.4rem;
  color: white;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

/* .partenaires_tab_type > div:hover {
  color: #a6a6a6;
} */

.partenaires_tab_type_active {
  background-color: #ef5a2b;
  color: white;
}

.partenaires_gen_creation_div {
  border-radius: 0.4rem;
  background-color: rgb(27, 30, 37);
  width: 100%;
  height: 65%;
  font-size: 1rem;
  margin-top: 1rem;
  padding: 1rem 0rem 0rem 2.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}
.partenaires_gen_creation_div2 {
  border-radius: 0.4rem;
  background-color: rgb(27, 30, 37);
  width: 100%;
  height: 100%;
  font-size: 1rem;
  /* margin-top: 1rem; */
  padding: 1rem 0rem 0rem 2.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.partenaires_gen_creation_div > span {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: white;
  position: relative;
  right: 1rem;
}

.gen_email {
  font-weight: 700;
}

.partenaires_gen_creation_div2 > button {
  width: 95%;
  height: 5.5vh;
  position: relative;
  left: 2rem;
  border-radius: 0.4rem;
  background-color: white;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  color: #496347;
}

.partenaires_gen_creation_div2 > span {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: white;
  position: relative;
  right: 1rem;
}

.gen_email {
  font-weight: 700;
}

.partenaires_gen_creation_div > button {
  width: 95%;
  height: 5.5vh;
  position: relative;
  font-family: "Inter", sans-serif;
  right: 0.5vw;
  border-radius: 0.4rem;
  background-color: white;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(27, 30, 37);
}

.gen_result {
  width: 95%;
  position: relative;
  right: 0.5rem;
  height: 5.5vh;
  border-radius: 0.4rem;
  background-color: rgb(49, 53, 62);
  color: white;
  outline: none;
  border: 0.085rem solid rgb(134, 132, 132);
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0 4.5rem;
}

.gen_div_button {
  width: 95%;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 5vh;
  right: 0.5rem;
  gap: 0.5rem;
}

.gen_div_button2 {
  width: 75%;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  left: 2rem;
  gap: 0.5rem;
}

.gen_div_button > button {
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.5rem 2rem;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: white;
  color: #2c333a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: "Inter", sans-serif;
}

.gen_div_button2 > button {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  width: 100%;
  height: 85%;
  font-weight: 600;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: white;
  color: #2c333a;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: "Inter", sans-serif;
}

.gen_div_button > button:hover {
  background-color: rgb(231, 233, 238);
}

.form_icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
  color: #d1e8ff;
  font-size: 1rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -2.8vh;
  left: 13.5vw;
}

.partenaires_add_form > div:nth-child(2) {
  width: 100%;
  height: 95%;
  /* background-color: tomato; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  gap: 0.6em;
  padding-left: 2rem;
}

.partenaires_form_field {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  position: relative;
  left: 2vw;
}

.partenaires_form_field2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  left: 2vw;
  font-family: "Inter", sans-serif;
}

.partenaires_form_field > label {
  font-size: 1rem;
  font-weight: 400;
  color: #929292;
  position: relative;
  left: -8.6vw;
}

.partenaires_form_field > input {
  font-size: 0.85rem;
  font-weight: 600;
  color: white;
  font-family: "Inter", sans-serif;
  outline: none;
  border: 0.089rem solid rgb(140, 135, 135);
  border-radius: 0.4rem;
  height: 5.5vh;
  width: 95%;
  background-color: rgb(27, 30, 37);
  padding: 0 0.5rem;
  position: relative;
  right: 3vw;
}

.partenaires_form_field2 > input {
  font-size: 1rem;
  outline: none;
  border: none;
  border-radius: 0.4rem;
  height: 5vh;
  width: 75%;
  background-color: rgb(27, 30, 37);
  padding: 0 0.5rem;
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  border: 0.09rem solid rgb(104, 104, 104);
  position: relative;
  right: 2.9vw;
}

.partenaires_add_form > div:nth-child(2) > button {
  width: 10vw;
  height: 5vh;
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  color: white;
  background-color: #557852;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  top: -1.5vh;
  left: 8vw;
}

.partenaires_add_form > div:nth-child(2) > button:hover {
  background-color: #496347;
}

.partenaires_form_logo {
  width: 109%;
  height: 32%;
  /* background-color: #2C333A; */
  position: relative;
  top: -10px;
  left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.partenaires_form_logo > div:nth-child(1) > label {
  width: 10vw;
  height: 5vh;
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  color: white;
  background-color: #5e91ae;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.7rem;
}

.partenaires_form_logo > div:nth-child(2) {
  width: 80px;
  height: 80px;
  border-radius: 0.8rem;
  background-color: #efefef;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
}
.curr_logo_partenaire {
  width: 100%;
  object-fit: contain;
}
/* styles for partenaires page */

/* styles for utilisateur  */

.Utilisateur {
  /* background-color: tomato; */
  width: 100vw;
  height: 92vh;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

/* .sect1_bilan > div:nth-child(1) {

    height: 80px;
    width: 80px;
    background-color: white;
    color: #2C333A;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5rem solid #557852;
    transition: all 0.3s;

} */
.sect1_bilanU1 > div:nth-child(1) {
  border: 0.5rem solid #0066b3;
}
.sect1_bilanU2 > div:nth-child(1) {
  border: 0.5rem solid #ff7900;
}

.user_sect2 {
  background-color: #d9d9d9;
  width: 37%;
  height: 99%;
  border-radius: 0.8rem;
}

.user_sect2_part_top {
  width: 100%;
  height: 15%;
  /* background-color: #2C333A; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_sect2_part_top > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 6vh;
  border-radius: 0.8rem;
  background-color: #1b1e25;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.13), 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.user_sect2_part_bottom {
  width: 100%;
  height: 40%;
  background-color: #d9d9d9;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_add_form {
  width: 90%;
  height: 70%;
  /* background-color: rgb(255, 255, 255); */
  border-radius: 0.8rem;
  /* box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11); */

  /* margin-bottom: 40px; */
}

/* .user_add_form div {
    border: solid 1px;
} */
/* .form_icon {

    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
    color: #d1e8ff;
    font-size: 1rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -2.8vh;
    left: 13.5vw;

} */

.user_add_form > div:nth-child(2) {
  width: 100%;
  height: 95%;
  /* background-color: tomato; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  gap: 0.6em;
  padding-left: 2rem;
}

.user_form_field {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: relative;
  /* left: 0.1vw; */
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: green; */
}

.user_form_field > input {
  font-size: 0.85rem;
  font-weight: 600;
  color: white;
  font-family: "Inter", sans-serif;
  outline: none;
  border: 0.089rem solid rgb(140, 135, 135);
  border-radius: 0.4rem;
  height: 5.5vh;
  width: 90%;
  background-color: rgb(27, 30, 37);
  padding: 0 0.5rem;
  position: relative;
  right: 0vw;
  margin: 0.15rem 0;
}
.icon-user {
  border: 5px solid #496347;
  border-radius: 50%;
  width: 13%;
  height: 8vh;
  display: flex;
  justify-content: center;
  margin-left: 43%;
  margin-bottom: 3px;
}
.user_form_field > input::placeholder {
  color: #ffffff;
}
.user_add_form > div:nth-child(2) > button {
  width: 10vw;
  height: 5vh;
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  color: white;
  background-color: #557852;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  top: -1.5vh;
  left: 8vw;
}

.user_add_form > div:nth-child(2) > button:hover {
  background-color: #496347;
}

.user_form_logo {
  width: 109%;
  height: 32%;
  /* background-color: #2C333A; */
  position: relative;
  top: -10px;
  left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.user_form_logo > div:nth-child(2) {
  width: 80px;
  height: 80px;
  border-radius: 0.8rem;
  background-color: #efefef;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
}
.curr_logo_partenaire {
  width: 100%;
  object-fit: contain;
}

.box-filter {
  /* border: solid 1px ; */
  width: 95%;
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  background-color: rgb(27, 30, 37);
  margin-bottom: 10px;
  position: relative;
  left: 0.7vw;
}
.range-age {
  display: flex;
  flex-direction: column;
}
.range-age > label {
  font-weight: bold;
  align-self: flex-start;
  margin-left: 50px;
  margin-top: 5px;
  color: #ffffff;
}
.range-age > label span {
  color: #a2a6a9;
}
.but-sexe {
  display: flex;
  /* border: solid 1px; */
  width: 40%;
  height: 100%;
  justify-content: space-between;
  margin-left: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.but-sexe :nth-child(0n + 1) {
  background-color: #2c333a;
  color: #496347;
  border: 2px solid #496347;
}
.but-sexe :nth-child(0n + 2) {
  background-color: #2c333a;
  color: #a2a6a9;
  border: 2px solid #a2a6a9;
}
.range-age > input {
  font-weight: bold;
  width: 52%;
  margin-left: 50px;
  margin-top: 8px;
}
.but-drop {
  color: #efefef;
  background-color: #2c333a;
  border-radius: 7px;
  border: none;
  width: 21%;
  height: 5%;
  margin-bottom: 10px;
}
.but-drops {
  color: #efefef;
  background-color: #ef5a2b;
  border-radius: 7px;
  border: none;
  width: 21%;
  height: 5%;
  margin-bottom: 10px;
  margin-left: 15px;
}
.filter {
  border: solid 1px;
  display: flex;
  font-size: 15px;
  justify-content: center;
  width: 24%;
  border-radius: 5rem;
  margin-top: 2px;
  align-self: flex-end;
  margin-right: 2px;
  color: #2c333a;
  background-color: #ffffff;
}

/* page donnees */

.donnees_sect1_bilan_left {
  position: absolute;
  left: 0.7vw;
  top: 9.5vh;
  width: 14vw;
  height: 4vh;
  border-radius: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.donnees_sect1_bilan_right {
  position: absolute;
  left: 54.6vw;
  top: 9.5vh;
  width: 9vw;
  height: 4vh;
  border-radius: 1rem;
  background-color: white;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.donnees_sect2_part_bottom {
  width: 100%;
  height: 85%;
  background-color: rgb(27, 30, 37);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.donnees_sect2_part_bottom_ope {
  width: 95%;
  height: 30%;
  background-color: #f6fbff;
  border-radius: 0.4rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  display: grid;
  grid-template-rows: 50% 50%;
}

.ope_top {
  background-color: #f5fbff;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ope_top > div:nth-child(1) {
  padding: 1rem;
  border-radius: 0.4rem;
  /* background-color: rgb(211, 139, 139); */
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  width: 5vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  position: relative;
  /* right: 8.7vw; */
}

.ope_top > div:nth-child(1) > img {
  object-fit: contain;
  width: 150%;
}

/*  */

.ope_bottom {
  /* background-color: #2C333A; */
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: relative;
  align-items: center;
}

.ope_botom_div {
  background-color: rgb(27, 30, 37);
  width: 97%;
  height: 5vh;
  font-size: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fa-eye,
.fa-tv {
  cursor: pointer;
}

/* style for details and modals */

/* this one when hovering table elements on dashboard page */

.sect3_elem_details {
  position: absolute;
  width: 100vw;
  left: 0;
  height: 92vh;
  top: 8vh;
  background-color: rgba(0, 0, 0, 0.599);
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: none; */
  z-index: 2;
}
.sect3_elem_detail {
  position: absolute;
  width: 100vw;
  left: 0;
  height: 100vh;
  top: 8vh;
  background-color: rgba(0, 0, 0, 0.599);
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: none; */
  z-index: 2;
}
.div_princi_updateGiga {
  position: absolute;
  width: 100vw;
  left: 0;
  height: 92vh;
  top: 8vh;
  background-color: rgba(0, 0, 0, 0.599);
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: none; */
  z-index: 2;
}

.sect3_elem_details > div {
  width: 80%;
  height: 93%;
  background-color: rgb(255, 255, 255);
  border-radius: 0.8rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  transition: all 0.2s ease-in-out;
  position: relative;
}
.sect3_elem_detail > div {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
 /*  border-radius: 0.8rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11); */
  transition: all 0.2s ease-in-out;
  position: relative;
  align-items: center;
  
}
.elem_details_closure {
  width: 27px;
  height: 27px;
  background-color: black;
  color: white;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1vw;
  top: 1vh;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s ease-in;
  font-size: 1rem;
}

.elem_details_closure:hover {
  /* transform: scale(1.1); */
  background-color: rgb(42, 42, 42);
}

/* login page css starting here */

.login_page {
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  background-color: #29363f;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.login_page_main {
  width: 80%;
  height: 45%;
  /* border-radius: 0.8rem; */
  /* background-color: white; */
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11); */
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.login_page_main > div:nth-child(1) {
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login_page_main > div:nth-child(2) {
  /* background-color: rgb(255, 211, 211); */
  border-left: 0.002rem solid rgb(130, 130, 130);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 1.2rem;
  padding-top: 5vh;
}

.login_form_field {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  position: relative;
  left: 2vw;
}

.login_form_field > label {
  font-size: 1rem;
  font-weight: 600;
  color: #929292;
  position: relative;
  left: -8.6vw;
}

.login_form_field > input {
  font-size: 1rem;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  height: 7.4vh;
  width: 22vw;
  background-color: #29363f;
  padding: 0 0.5rem;
  border: 0.14rem solid #58636f;
  font-weight: 500;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
}
.login_form_field > input:focus {
  border: 0.14rem solid #4eac04;
}

.login_page_main_button {
  width: 22vw;
  height: 7.4vh;
  padding: 0.2rem 1rem;
  border-radius: 0.25rem;
  color: white;
  background-color: #4eac04;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  left: 2vw;
  top: 5vh;
  font-size: 1rem;
}

.login_form_question_div {
  font-size: 0.9rem;
  color: rgba(159, 159, 159, 0.641);
  position: relative;
  top: 5vh;
  left: 2vw;
  cursor: pointer;
  transition: all 0.1s ease;
}
.login_form_question_div:hover {
  color: white;
}

.login_form_main_logo {
  width: 25%;
  object-fit: contain;
}

/* user(admin) settings page styles from here */
/* user(admin) settings page styles from here */

.settings_page {
  /* background-color: tomato; */
  width: 100vw;
  height: 92vh;
  padding: 0.5rem 0.5rem;
  display: grid;
  gap: 1vh;
  grid-template-rows: 24% 75%;
}

.settings_page > div:nth-child(1) {
  background-color: #edf5ff;
  width: 99vw;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
}

.settings_page > div:nth-child(1) > div:nth-child(1) {
  background-color: #edf5ff;
  position: relative;
  height: 30%;
}

.settings_page > div:nth-child(1) > div:nth-child(1) > h4 {
  position: absolute;
  left: 1vw;
  top: 1vh;
}

.settings_user_details_top {
  width: inherit;
  background-color: #edf5ff;
  /* background-color: tomato; */
  height: 70%;
  position: relative;
}
.settings_user_details_top > div:nth-child(1) {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #29363f;
  position: absolute;
  left: 2vw;
  top: 0.5vh;
  z-index: 1;
  border: 0.35rem solid white;
}

.settings_user_details_top > div:nth-child(2) {
  width: inherit;
  height: 10vh;
  position: absolute;
  background-color: #4eac04;
  color: white;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.settings_user_details_top > div:nth-child(2) > h4 {
  position: absolute;
  left: 11vw;
}

.settings_page > div:nth-child(2) {
  background-color: #edf5ff;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.settings_user_details_bottom > div:nth-child(1) {
  height: 7vh;
  width: 100%;
  position: relative;
  /* background-color: tomato; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.settings_user_details_bottom > div:nth-child(1) > h6 {
  color: rgb(39, 39, 39);
}

.settings_user_details_bottom > div:nth-child(1) > span {
  color: rgb(173, 173, 173);
}

.settings_page_infos_box {
  height: 10vh;
  width: 100%;
  border-top: 0.02rem solid rgb(210, 210, 210);
  /* border-bottom: 0.02rem solid rgb(210, 210, 210); */
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.settings_page_infos_box > label {
  position: absolute;
  left: 2vw;
  font-size: 1rem;
  font-weight: 600;
}

.settings_page_infos_box > input {
  position: absolute;
  left: 8vw;
  font-size: 1rem;
  outline: none;
  border: none;
  border-radius: 0.4rem;
  height: 6vh;
  width: 30vw;
  background-color: #2c333a;
  padding: 0 0.5rem;
  color: #ffffff;
}

.settings_up_button {
  position: absolute;
  color: #4eac04;
  font-size: 1rem;
  right: 40vw;
  cursor: pointer;
  padding: 0.25rem 1rem;
  background-color: #e3eeff;
  border-radius: 0.4rem;
  font-weight: bold;
}

/* partenaires reg pages */
.partenaires_reg_page {
  width: 80%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.partenaires_reg_page_inputs {
  /* background-color: rgb(255, 211, 211); */
  /* border-left: 0.002rem solid rgb(130, 130, 130); */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  /* padding-top: 5vh; */
  position: relative;
  bottom: 8vh;
}

.partenaires_reg_page > img {
  width: 10%;
  object-fit: contain;
  position: relative;
  bottom: 20vh;
}

.partenaires_reg_page > span {
  font-size: 1.2rem;
  font-weight: 600;
  color: #4eac04;
  position: relative;
  bottom: 15vh;
  left: 1.5vw;
}

.form_page {
  width: 100vw;
  height: 100vh;
  top: 0;
  position: absolute;
  background-color: #29363f;
  display: flex;
  flex-direction: column;
  background-size: cover;
}

.form_page > div:nth-child(1) {
  width: 100%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  /* background-color: #d1d9df; */
}

.form_page > div:nth-child(1) > span {
  font-size: 1.5rem;
  font-weight: 600;
  color: #4eac04;
  position: relative;
  bottom: 6vh;
}

.form_page > div:nth-child(2) {
  width: 100%;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  background-color: #d6edff;
  z-index: 1;
}

.regform_form {
  width: 45%;
  height: 120%;
  background-color: #ffffff;
  position: relative;
  bottom: 8vh;
  border-radius: 0.2rem;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.regform_form_fields_div {
  height: 100vh;
  width: 90%;
  background-color: #f2f2f2;
  border-radius: 0.4rem;
  gap: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.regform_form_fields_div > div {
  height: 8vh;
  width: 100%;
  /* background-color: tomato; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  padding: 0 0.5rem;
}
/* login page modifs */

.login_loader {
  width: 15.5%;
  position: absolute;
  bottom: 10vh;
  left: 9.5vw;
  transition: all 0.2s ease-in-out;
}

/* partenaire reg page loader styles */

.reg_loader {
  /* width: 100%; */
  position: absolute;
  top: 4vh;
  left: 2vw;
  transition: all 0.2s ease-in-out;
}

/* partenaires logo selection with preview */

.part_logo_preview_div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  /* background-color: #d1d9df; */
}

.part_logo_preview {
  width: 5vw;
  height: 10vh;
  object-fit: contain;
  border-radius: 0.8rem;
  border: 0.02rem solid rgb(210, 210, 210);
  transition: all 0.2s ease-in-out;
}

.part_img_label {
  background-color: white;
  color: #4eac04;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.25rem 1rem;
  cursor: pointer;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  border-radius: 0.4rem;
}

/* partenaires page specific details */

.part_details_div {
  width: 95%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 8vh;
  left: 2vw;
  /* background-color: #f6f8ff; */
  border-radius: 0.4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0rem;
}
.part_details_divs {
  width: 95%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 8vh;
  left: 2vw;
  /* background-color: #f6f8ff; */
  border-radius: 0.4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0rem;
}

.part_details_div > div:nth-child(1) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11); */
  /* background-color: #ffffff; */
  border-radius: 0.8rem;
}

.part_details_div > div:nth-child(2) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  /* background-color: #f2f9ff; */
  border-radius: 0.8rem;
}

.row1_details_header {
  width: 80%;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  align-items: flex-start;
  padding: 0.5rem;
  gap: 0.2rem;
  /* justify-content: flex-start; */
  top: 0;
  border-bottom: 0.01rem solid rgb(204, 204, 204);
  color: #797979;
}

.row1_details_details {
  width: 80%;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  align-items: flex-start;
  padding: 0.5rem;
  gap: 0.5rem;
  /* justify-content: flex-start; */
  top: 9vh;
  color: #797979;
}
.row1_details_detail {
  width: 85%;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  align-items: flex-start;
  padding: 0.5rem;
  gap: 0.5rem;
  /* justify-content: flex-start; */
  top: 9vh;
  color: #797979;
}
.row1_details_detail > div {
  width: 100%;
 display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ece7e3;
  border-radius: 0.4rem;
  padding: 0.5rem;
}
.row1_details_details > div {
  width: 100%;
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.row1_details_details > div > span:nth-child(2) {
  /* font-size: 1.2rem; */
  font-weight: 600;
  color: #46920c;
  background-color: #4dac0431;
  /* background-color: #ac180431; */
  padding: 0.2rem 1rem;
  border-radius: 1rem;
}

.row_status_div > div {
  width: 95%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f7fbff;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  position: absolute;
  top: 1vh;
  left: 0.8vw;
}

.row_status_div_valide {
  /* font-size: 1.2rem; */
  font-weight: 600;
  color: #46920c;
  background-color: #4dac0431;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
}

.row_status_div_nonvalide {
  /* font-size: 1.2rem; */
  font-weight: 600;
  color: #92150c;
  background-color: #92150c39;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
}

/* update for filter and tables pagination */

.search-bar {
  position: absolute;
  left: 29vw;
  height: 5vh;
  width: 20vw;
  border: none;
  outline: none;
  border-radius: 1.5rem;
  padding-left: 1vw;
  top: 51.6vh;
}

.pagination {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 93vh;
  right: 23.5vw;
  /* background-color: #f6f8ff; */
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0 1rem;
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11); */
  /* background-color: #ffffff; */
  border-radius: 0.8rem;
}

.pagination-button {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  border: 0.01rem solid rgb(204, 204, 204);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 0 0.5rem;
  font-size: 1rem;
  color: #797979;
  font-weight: 600;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
}

.pagination-button:active {
  transform: scale(0.9);
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
}

.hs_top_titre {
  position: relative;
  left: -13.7vw;
  width: auto;
  height: auto;
  color: #131320;
}

.hs_title > span {
  font-size: 1rem;
  font-weight: 600;
  color: #131320;
}

.search-bar-container {
  position: absolute;
  height: 5vh;
  width: 25vw;
  right: 2vw;
  /* background-color: tomato; */
  border: none;
  outline: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border-radius: 1.5rem; */
  padding-left: 1vw;
}

.search-bar-container2 {
  position: relative;
  height: 5vh;
  width: 35vw;
  /* background-color: tomato; */
  border: none;
  outline: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* border-radius: 1.5rem; */
  padding-left: 1vw;
}

.search-bar-hs {
  position: relative;
  right: 1.5vw;
  height: 5vh;
  width: 20vw;
  border: #131320 solid 0.101rem;
  outline: none;
  border-radius: 0.4rem;
  padding-left: 1vw;
  background-color: #d9d9d9;
  /* ;   top: 51.6vh; */
}
.search-bar-hs2 {
  position: absolute;
  /* right: 1.5vw; */
  height: 5vh;
  width: 20vw;
  border: #131320 solid 0.101rem;
  outline: none;
  border-radius: 0.4rem;
  padding-left: 1vw;
  background-color: #d9d9d9;
  /* ;   top: 51.6vh; */
}

.search-bar-hs3 {
  position: absolute;
  /* right: 1.5vw; */
  height: 5vh;
  width: 20vw;
  border: #131320 solid 0.101rem;
  outline: none;
  border-radius: 0.4rem;
  padding-left: 1vw;
  background-color: #d9d9d9;
  /* ;   top: 51.6vh; */
}

.search-button {
  position: relative;
  /* right: 1.5vw; */
  height: 4.5vh;
  width: 5vw;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  /* padding-left: 1vw; */
  background-color: #000000;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-button2 {
  position: relative;
  left: 13vw;
  height: 5vh;
  padding: 0 1.5rem;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  /* padding-left: 1vw; */
  background-color: #000000;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-button-disabled {
  position: relative;
  height: 4.5vh;
  width: 5vw;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  /* padding-left: 1vw; */
  background-color: dimgrey;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-button-disabled2 {
  position: relative;
  left: 13vw;

  height: 5vh;
  /* width: 15vw; */
  padding: 0 1.5rem;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  /* padding-left: 1vw; */
  background-color: dimgrey;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-button3 {
  position: relative;
  left: 24vw;
  height: 5vh;
  padding: 0 1.5rem;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  /* padding-left: 1vw; */
  background-color: #000000;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-hs {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 2vh;
  right: 2vw;
  /* background-color: #f6f8ff; */
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0 1rem;
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .13), 0 1px 4px 0 rgba(0, 0, 0, .11); */
  /* background-color: #ffffff; */
  border-radius: 0.8rem;
}

.search-bar-partenaire {
  position: relative;
  /* left: 29vw; */
  height: 5vh;
  width: 20vw;
  border: none;
  outline: none;
  border-radius: 1.5rem;
  padding-left: 1vw;
  top: -1vh;
  left: -2vw;
}

/* styling page ajout video */

.addVideo_input {
  width: 100%;
  height: 5vh;
  border: none;
  outline: none;
  border-radius: 0.4rem;
  padding-left: 1vw;
  margin-bottom: 0vh;
  font-size: 1rem;
  font-weight: 600;
  color: #797979;
  background-color: #f6f6f6;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11);
}

.addVideo_input:focus {
  border: #46920c 0.1rem solid;
}
.btn_see_more {
  width: 6.5vw;
  height: 4.4vh;
  border: none;
  outline: none;
  border-radius: 0.55rem;
  padding-left: 0vw;
  margin-bottom: 0vh;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  position: relative;
  top: 0.6vh;
  color: white;
  background-color: black;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partStatus1 {
  /* width: 100%; */
  /* height: 5vh; */
  border: none;
  outline: none;
  background-color: #69dd1043;
  padding: 0.2rem 1rem;
  border-radius: 2rem;
  color: #2f9009;
}

.partStatus2 {
  /* width: 100%; */
  /* height: 5vh; */
  border: none;
  outline: none;
  background-color: #dd43103f;
  padding: 0.2rem 1rem;
  border-radius: 2rem;
  color: #901d09;
}

.partStatusFilter {
  position: relative;
  width: 15vw;
  height: 5vh;
  border: none;
  outline: none;
  border-radius: 1.5rem;
  /* background-color: #4EAC04; */
}

.partStatusFilter > button {
  /* width: 10vw; */
  /* border: 0.01rem solid rgb(204, 204, 204); */
  border: none;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 0 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 0.9rem;
  font-family: "Inter", sans-serif;
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11); */
  /* background-color: #46920c; */
  color: "white";
}

.partStatusFilter > button:active {
  transform: scale(0.99);
  /* box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.13), 0 1px 4px 0 rgba(0, 0, 0, 0.11); */
}


@keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
  
}

.blink {
  color: #4EAC04;
  animation: blink 1s infinite;
  position: absolute;
  left: 15vw;
}
