@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.body-gene {
  display: flex;
  width: 100vw;
  height: 100%;
  font-family: "Inter", sans-serif;
}
.view-vision {
  /* border: solid 1px; */
  display: flex;
  flex-direction: column;
  width: 55vw;
  /* background-color: #2C333A; */
  align-items: center;
}

.view-form {
  /* border: solid 1px green; */
  display: flex;
  flex-direction: column;
  width: 45vw;
  /* background-color: aquamarine; */
  /* margin-right: 10px; */
  /* align-items: center;  */
  /* position: relative; */

}

.visionnagesonform{


  height: 57vh;
  width: 97%;
  background-color: rgb(236, 236, 236);
  /* position: relative; */
  top: 3vh;
  border-radius: 0.7rem;


}

.information-vision {
  /* border: solid 1px black; */
  height: 60%;
  background-color: #1b1e25;
  width: 95%;
  border-radius: 9px;
  margin-top: 15px;
  margin-bottom: 25px;
  color: #ffff;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.information-vision :nth-child(0n + 1) {
  margin-left: 10px;
}
.information-vision :nth-child(0n + 2) {
  margin-left: 10px;
}
.information-vision :nth-child(0n + 3) {
  margin-left: 10px;
}

.list-vision {
  /* border: solid 1px black; */
  height: 100%;
  background-color: #ffffff;
  width: 95%;
  border-radius: 9px;
}

thead {
  background-color: #333;
  color: #fff;
}

.form {
  /* border: solid 1px black; */
  width: 97%;


  margin-top: 15px;
  border-radius: 9px;
  height: 28.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  flex-direction: column;
  background-color: #d9d9d9;
}
.log-video {
  /* border: solid 1px; */
  height: 33%;
  width: 100%;
  /* background-color: tomato; */

  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.log-video span {
  width: 40%;
  height: 20%;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.95rem;
  /* font-weight: bolder; */
  color: rgb(27, 30, 37);
  border-radius: 10px;
  border: none;
  /* background-color: #2C333A; */
}
.log-videoButton {
  width: 25rem;
  height: 5.5vh;
  border-radius: 9px;
  font-family: "Inter", sans-serif;
  /* height: 35em; */
  background-color: rgb(27, 30, 37);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  position: relative;
  top: 2vh;
  border: none;
  outline: none;
}

.log-videoButton i {
  font-size: 1.4rem;
}
.part-choose {
  /* border: solid 1px ; */
  height: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .part-choose span{
    border: solid 1px ;
    width: 97%;
    height: 20%;
    margin-top: 6px;
    background-color: #EFEFEF;
    border-radius: 5px;
    border: none;
} */
.but-choose {
  display: flex;
  justify-content: center;
  width: 97%;
  margin-top: auto;
  margin-bottom: 17px;
}
.but-choose :nth-child(0n + 1) {
  width: 40%;
  margin-right: 4px;
  height: 160%;
  border-radius: 6px;
  border: none;
  color: #2c333a;
  background-color: #efefef;
  font-weight: bolder;
}
.but-choose :nth-child(0n + 2) {
  width: 25%;
  height: 160%;
  border-radius: 6px;
  border: none;
  color: #2c333a;
  background-color: #5e91ae;
  font-weight: bolder;
}
.box-video {
  /* border: solid 1px ; */
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.but-add {
  width: 16%;
  height: 6%;
  color: #efefef;
  border-radius: 6px;
  border: none;
  font-weight: bolder;
  background-color: #aaa1a1;
  align-self: center;
  margin: 10px;
}

.visionnage_sect1 {
  /* background-color: rgba(255, 255, 255, 0.425); */
  width: 67%;
  height: 99%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.visionnage_sect1_top {
  background-color: #2c333a;
  width: 100%;
  height: 32%;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
}

.visionnage_sect1_bottom {
  background-color: #ffffff;
  width: 100%;
  height: 68%;
  border-radius: 9px;
}

.visionnage_sect1_bottom_sect_top {
  width: 100%;
  height: 13%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  padding: 0 4rem;
  background-color: #557852;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  font-weight: 700;
}

.visionnage_sect1_bottom_sect_top > div:nth-child(1) {
  border-radius: 0.7rem;
  /* background-color: #2C333A; */
  color: #2c333a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.2rem 0.7rem;
}

.visionnage_sect1_bottom_sect_top > div:nth-child(2) {
  width: 20px;
  height: 20px;
  /* border-radius: 50%; */
  background-color: #ffffff;
}

/* partenaires page sect1 bottom part table style */

.visionnage_sect1_bottom_sect_table {
  width: 100%;
  height: 88%;
  padding: 0.6rem 0.2rem;
  /* background-color: rgba(255, 255, 255, 0.505); */
}

.visionnage_sect1_bottom_sect_table > table {
  border-collapse: separate;
  border-spacing: 6px 6px;
  margin-top: -4px;
  position: relative;
  top: 20px;
}

.visionnage_sect1_bottom_sect_table > table > thead {
  font-size: 0.9rem;
}

.visionnage_sect1_bottom_sect_table > table > thead > tr {
  background-color: #2c333a;
  color: white;
}

.visionnage_sect1_bottom_sect_table > table > thead > tr > th {
  padding: 0.2rem 1.7rem;
  border-radius: 0.7rem;
}

.visionnage_sect1_bottom_sect_table > table > tbody > tr {
  margin: 0.5rem 0;
  background-color: rgb(255, 255, 255);
  color: #2c333a;
}

.visionnage_sect1_bottom_sect_table > table > tbody > tr > td {
  padding: 0.3rem 0;
}

.visionnage_sect1_bottom_sect_table > table > tbody {
  font-size: 0.9rem;
}
.part_form_addVideo {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.form_addVideo {
  /* border: solid 1px; */
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50%;
  margin-top: 100px;
  justify-content: center;
  justify-content: space-around;
  margin-left: 100px;
}
.form_addVideo div {
  /* border: solid 1px; */
  display: flex;
  justify-content: space-around;
}
.add_video {
  margin-left: 60px;
}
.img_popup_addVideo {
  border: solid 1px;
}
.liste_videos > th {
  padding: 8px;
  margin: 10px;
}
